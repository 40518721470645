<script>
import axios from 'axios';
export default {
    name: "authorize_b",

    data() {
        return {
            backUrl: {
                authIn: 'http://localhost:9090/authorize/authIn',
                getCodeTpass: 'http://localhost:9090/authorize/getCodeTpass',
                auth: 'http://localhost:9090/authorize/auth',
                getTemplate: 'http://localhost:9090/authorize/getTemplate',
                getPersonalTaxQrCode: 'http://localhost:9090/authorize/getPersonalTaxQrCode',
                getTaxAppQrCode: 'http://localhost:9090/authorize/getTaxAppQrCode'
            },
            activeName: 'tab-0',
            radio: '1',
            qrcodeUrl: '',
            treeId: '',
            treeId_qrcode: '',
            clientId: '',
            errorMessage: "",
            verify: true,
            dialogVisible: false,
            dialogVisiblePassword: false,
            dialogVisibleResponse: false, // 控制响应消息弹窗的可见性
            responseMessage: '', // 存储响应消息
            taxpayerId: '',
            companyName: '',
            value: '',
            checked: false,
            qrCode: '',
            formData: {
                taxpayerId: '',
                companyName: '',
                username: '',
                token: '',
                password: '',
                cardSelect: '',
                smsCode: '',
                treeId: 'JIANGSU-4',
                encryptionPwd: true,
                desc: ''
            },
            form: {},
            formRules: {},
            json: {}
        };
    },
    created() {
        // this.initializeForm();
        const urlParams = new URLSearchParams(window.location.search);
        this.formData.taxpayerId = urlParams.get('shxydm') || '';
        this.formData.companyName = urlParams.get('companyName') || '';
        this.formData.token = urlParams.get('token') || '';
        this.clientId = urlParams.get('clientId') || '';
        // this.verifyToken()
        this.getJson();
    },
    computed: {
        filteredTreeNode() {
            const excludedTabs = ['扫登录'];
            return this.json.data.treeNode.filter(node => !excludedTabs.includes(node.card.tabShowName));
        }
    },
    methods: {
        verifyToken() {
            const formData = {
                token: this.formData.token
            };
            axios.get('/authorize/verifyToken', {
                params: formData
            })
                .then(response => {
                    if (response.data === 'success') {
                        this.verify = true;
                        // console.log("afterSuccess:", this.verify)
                        this.getJson();
                    } else {
                        this.verify = false;
                        this.errorMessage = response.data;
                        // console.log(this.errorMessage);
                    }
                    // console.log(this.verify);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        getJson() {
            // console.log('beforeGetJson', this.verify)
            if (this.verify === true) {
                const formData = {
                    shxydm: this.formData.taxpayerId,
                    companyName: this.formData.companyName,
                    clientId: this.clientId
                };
                axios.get('/authorize/getTemplate', {
                    params: formData
                })
                    .then(response => {
                        if (response.data.success === false) {
                            // console.log(response.data);
                            // console.log(response.data.success);
                            // console.log(response.data.messageError);
                            this.verify = false
                            this.errorMessage = response.data
                        }
                        this.json = response.data; // 设置响应消息
                        this.formData.companyName = response.data.companyName;
                        if (this.json.data.treeNode.length > 0) {
                            this.treeId = this.json.data.treeNode[0].id; // 赋值第一个treeNode的id
                        }
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            }
        },
        handleConfirm() {
            this.dialogVisible = false;
            this.checked = true;
            this.onSubmit();
        },
        initializeForm() {
            this.json.data.treeNode.forEach(node => {
                node.card.keyValues.forEach(keyValue => {
                    this.$set(this.form, keyValue.id, keyValue.initialValue);
                });

                Object.keys(node.rules).forEach(ruleKey => {
                    this.$set(this.formRules, ruleKey, node.rules[ruleKey]);
                });
            });
        },
        handleForgetPassword(link) {
            window.open(link, '_blank');
        },
        sendSmsCode(sendSms) {
            this.smsButtonDisabled = true;
            this.smsButtonLabel = `${sendSms.messageCountDown}秒后重试`;

            axios.post(sendSms.apiUrl, sendSms.apiParams)
                .then(response => {
                    console.log(response.data)
                    this.startCountdown(sendSms.messageCountDown);
                })
                .catch(error => {
                    console.error(error);
                    this.smsButtonDisabled = false;
                    this.smsButtonLabel = sendSms.buttonLabel;
                });
        },
        startCountdown(countdown) {
            if (countdown > 0) {
                setTimeout(() => {
                    countdown--;
                    this.smsButtonLabel = `${countdown}秒后重试`;
                    this.startCountdown(countdown);
                }, 1000);
            } else {
                this.smsButtonDisabled = false;
                this.smsButtonLabel = '获取验证码';
            }
        },

        onSubmit() {
            if (this.checked !== true) {
                this.dialogVisible = true
                return;
            }
            const formData = {
                taxpayerId: this.formData.taxpayerId,
                companyName: this.formData.companyName,
                username: this.formData.username,
                cardSelect: this.formData.cardSelect,
                smsCode: this.formData.smsCode,
                password: this.formData.password,
                number: this.formData.number,
                treeId: this.treeId,
                clientId: this.clientId
            };
            console.log("前端参数：", formData)
            axios.post('/authorize/auth', formData, {
            })
                .then(response => {
                    this.responseMessage = response.data; // 设置响应消息
                    this.dialogVisibleResponse = true; // 显示弹窗
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },

        getCode() {
            const formData = {
                taxpayerId: this.formData.taxpayerId,
                companyName: this.formData.companyName,
                username: this.formData.username,
                password: this.formData.password,
                treeId: this.treeId
            };
            axios.post('/authorize/getCodeTpass', formData, {
            })
                .then(response => {

                    this.responseMessage = response.data; // 设置响应消息
                    this.dialogVisibleResponse = true; // 显示弹窗
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        handleClick(tab) {
            this.json.data.treeNode.forEach(node => {
                if (tab === node.card.tabShowName)
                    this.treeId_qrcode = node.id
            });
        },
        // async fetchQRCode() {
        //     try {
        //         const response = await axios.post(this.qrcode.apiUrl, { id: optionId });
        //         if (response.data.code === '200') {
        //             const qrCode = response.data.data.qrCodeList[0].qrCode;
        //             this.qrCode = qrCode; // 将二维码数据存储在组件的状态中
        //         } else {
        //             console.error('API Error:', response.data.message);
        //         }
        //     } catch (error) {
        //         console.error('Request failed:', error);
        //     }
        // }
        handleRadioChange(value) {
            console.log('选中的值:', value);
            // 在这里调用不同的后端接口
            this.callApi(value);
        },

        callApi(option) {
            // 根据选中的值调用不同的后端接口
            let apiUrl = '';

            switch (option) {
                case '个税APP':
                    apiUrl = '/authorize/getPersonalTaxQrCode';
                    break;
                case '税务APP':
                    apiUrl = '/api/endpoint2';
                    break;
                default:
                    console.error('未定义的选项');
                    return;
            }

            const formData = {
                shxydm: this.formData.taxpayerId,
                companyName: this.formData.companyName,
                cardSelect: this.clientId,
                treeId: this.treeId_qrcode
            };

            // 调用后端接口
            axios.post(apiUrl, formData, {
            })
                .then(response => {
                    console.log('响应数据:', response.data);
                })
                .catch(error => {
                    console.error('API 调用错误:', error);
                });
        }
    }


}
</script>

<template>
    <div :class="{ 'container': verify }">
        <div v-if="!verify" style="text-align: left;">
            {{ errorMessage }}
        </div>
        <el-card class="box-card" v-if="verify">
            <el-form :model="form" :rules="formRules" ref="form" label-width="120px">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-if="json.data.treeNode.length > 0" :label="json.data.treeNode[0].card.tabShowName"
                        :name="'tab-0'" :id="json.data.treeNode[0].id">
                        <div v-for="(keyValue, keyIndex) in json.data.treeNode[0].card.keyValues" :key="keyIndex">
                            <el-form-item v-if="keyValue.type === 'input'" :prop="keyValue.id" :label="keyValue.label"
                                class="full-width">
                                <el-input v-model="formData[keyValue.id]" :type="keyValue.inputType"
                                    :placeholder="keyValue.placeholder" :disabled="keyValue.disabled"
                                    :readonly="keyValue.readOnly"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="keyValue.type === 'dropdownSelect'" :prop="keyValue.id"
                                :label="keyValue.label" class="full-width">
                                <el-select v-model="form[keyValue.id]" :placeholder="keyValue.placeholder">
                                    <el-option v-for="(option, optionIndex) in keyValue.selectOptions"
                                        :key="optionIndex" :label="option.label" :value="option.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-else-if="keyValue.type === 'qrcodeLogin'" :prop="keyValue.id"
                                :label="keyValue.label">
                                <el-qrcode :value="keyValue.qrcode.apiUrl"></el-qrcode>
                            </el-form-item>
                            <el-form-item v-else-if="keyValue.type === 'smsCode'" :prop="keyValue.id"
                                :label="keyValue.label">
                                <el-input v-model="formData[keyValue.id]" :placeholder="keyValue.placeholder"
                                    :disabled="keyValue.disabled" :readonly="keyValue.readOnly"
                                    :maxlength="keyValue.maxlength">
                                    <template slot="append">
                                        <el-button @click="getCode()" :disabled="smsButtonDisabled">
                                            {{ keyValue.sendSms.buttonLabel }}
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <el-button type="primary" @click="onSubmit()"
                            style="width: 100%; height: 30px; padding-bottom: 24px;">授权登录</el-button>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-card>
        <el-dialog :visible.sync="dialogVisible" width="50%" title="授权协议">
            <el-scrollbar style="max-height: 400px;  overflow-y: auto; overflow-x: hidden;">
                <div class="agreement-content" data-v-5523dd00="">
                    <p><strong>企业</strong><strong>授权</strong><strong>协议</strong><strong>书</strong></p>
                    <p>尊敬的客户：</p>
                    <p>请在签署企业授权协议书（以下简称“本协议”）前，仔细阅读本协议全部内容，充分理解本授权书的条款内容，特别是限制贵司权利及加粗条款，以知悉贵司在本服务中的权利、义务。</p>
                    <p>&nbsp;</p>
                    <p>一、定义及解释</p>
                    <p><strong>1、“企业财税数据采集系统”服务</strong>：指【广西北部湾大数据交易中心有限公司】（以下简称“本公司”）及为本次【信贷项目】与本公司发生业务、技术关系的相关合作机构【以下简称“合作方”】所使用财税采集软件采集贵司经营数据的服务，相关合作机构包括了本次提供企业财税数据采集系统的技术服务方（以下简称“技术服务方”）。
                    </p>
                    <p>2、<strong>经营数据</strong>：指过往的开票数据、纳税数据、财务数据、信贷数据、高管的职务数据和其他数据等，包括但不限于：</p>
                    <p>（1）开票金额、开票种类、开票对象、开票税额、开票商品；</p>
                    <p>（2）资产负债表、利润表、纳税信息等级、完税信息、欠税信息；</p>
                    <p>（3）违法违章信息、企业名称、字号、企业经营地址、企业经营范围、企业联系电话、企业员工人数、注册资本缴纳、知识产权情况、行政处罚及诉讼情况、司法执行情况；</p>
                    <p>（4）公司使用电子税务局所注册并实名认证的账号及密码、纳税人识别号、法定代表人姓名、年龄、身份证号码、手机号、验证码、CA证书等相关信息。</p>
                    <p>前述经营数据如涉及个人信息的，贵司已经过个人信息主体确认，并且该等信息均属于本协议授权使用范围的企业信息。</p>
                    <p><strong>3</strong><strong>、处理：</strong>指对公司经营数据进行采集、查询或验证、保存、使用、整理、分析、比对、演算、归纳及（或）加工，并输出报告等各项操作。
                    </p>
                    <p>4、您：指贵司的法定代表人。</p>
                    <p>&nbsp;</p>
                    <p>二、授权人</p>
                    <p>贵司承诺并确认在签署本协议前，是依据中国法律注册成立并有效存续的住所位于中国境内（不包含港澳台地区）的中国企业法人、事业单位及其它经济组织，具有独立承担法律责任的完全民事行为能力，本授权书是贵司真实的意思表示。
                    </p>
                    <p>鉴于，贵司在本公司处申请了【信贷业务】，为了能让贵司的信息及时被本公司及合作方查询，并用以评估贵司的资信情况，授权人自愿签署本协议，同意本公司在授权书约定的范围内，对贵司自行提交的或由本公司所搜集的贵司相关信息进行处理。
                    </p>
                    <p>&nbsp;</p>
                    <p>三、授权流程及服务规则</p>
                    <p><strong>（一）您及贵司应当知晓经营数据对于您及贵司而言是相当私密而重要的,该等信息被提供和使用存在一定的风险，这些风险包括但不限于:纳入这些信息对贵司的贷款评分等结果可能产生的不利影响，您及贵司已经充分理解并知晓上述风险。</strong>
                    </p>
                    <p><strong>（二）贵司同意授权的方式包括但不限于以下：</strong></p>
                    <p><strong>1.本公司官方网站</strong><strong>/手机银行/直销银行等电子渠道注册并登录后，</strong><strong>完成并提交任一产品的申请信息页，并同意授权给本公司及合作方。</strong>
                    </p>
                    <p><strong>2.通过安装采集系统软件</strong><strong>并签订</strong><strong>电子协议授权</strong><strong>给本公司及合作方</strong><strong>。</strong>
                    </p>
                    <p><strong>&nbsp;</strong></p>
                    <p>四、授权范围</p>
                    <p>授权人授权本公司及合作方进行如下行为：</p>
                    <ol>
                        <p><strong>1</strong><strong>.
                            </strong><strong>同意本公司及合作方在授权书约定的范围内，对贵司自行提交的或由本公司、合作方所搜集的贵司相关经营数据进行处理。</strong></p>
                        <p><strong>2</strong><strong>.</strong><strong>同意本公司及合作方在授权期限内收集并处理贵司后续动态变更的经营数据。</strong></p>
                        <p><strong>3</strong><strong>.</strong><strong>同意本公司及合作方将贵司经营数据用于自身非商业性质的科研用途或产品迭代。</strong>
                        </p>
                    </ol>
                    <p>&nbsp;</p>
                    <p>五、数据使用与共享</p>
                    <p><strong>1.</strong><strong>为降低贵司身份被冒用的风险事件发生而影响贵司享受有关服务的风险，贵司同意本公司在收到第三方提供贵司的信息时，授权本公司可通过贵司所留存的信息进行对比分析，直接向该第三方输出贵司的信息是否真实、准确、有效，以及是否存在关联风险事件的判断结果信息。</strong>
                    </p>
                    <p>2.贵司理解，本公司将可能向贵司推荐与本公司合作的第三方金融机构的金融产品或服务，如贵司不希望接收前述信息，可通过联络本公司客服人员进行告知。</p>
                    <p>3.贵司如出现违反有关法律法规及相关规定或者违反本授权书时，本公司可为了维护前述合法权益，向行政机构、司法机构或其他有权机构披露，但应在必要范围内。</p>
                    <p>&nbsp;</p>
                    <p>六、授权期限</p>
                    <p>自本授权书生效之日起至贵司在【信贷项目】完全履行完毕之日或本公司提供的相应服务结束之日止，在授权期限内，本授权书不可撤销。</p>
                    <p>&nbsp;</p>
                    <p>七、授权使用限制</p>
                    <p>1、在本公司要求贵司提供经营数据协助时，贵司将在收到书面通知后的【10】日内立即提供；如因贵司未能协助提供相关信息导致本公司或合作方无法收集经营数据的，贵司将自行承担责任。</p>
                    <p>2、本公司及合作方提供的企业财税数据采集系统服务应经过贵司授权，未经贵司允许，本公司及合作方不得将经营数据及加工整理后的信息私自提供给其他公司。</p>
                    <p>3、贵司已知晓授权期限内相关信息的重要性，因贵司自身原因导致经营数据泄露给其他第三方的，由贵司自行承担相关责任。</p>
                    <p>4、技术服务方在授权期限内仅提供经营数据采集、整理、加工、归纳等技术服务，技术服务方应在信息采集完成后的及时删除所有采集信息，留存时间不得超过15天。</p>
                    <p>&nbsp;</p>
                    <p>八、贵司承诺</p>
                    <p>1、贵司授权本公司及合作方采集和使用的经营数据不会侵犯任何第三方的商业秘密或合法权利，亦不会违反贵司在任何合同项下的义务或责任。</p>
                    <p>2、当贵司的行为涉嫌违反法律法规或违反本授权书的，本公司或合作方有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息，或直接停止提供服务。如贵司的行为使本公司或合作方遭受任何损失的（包括但不限于受到第三方的索赔、受到行政管理部门的处罚等），贵司还应当赔偿或补偿本公司或合作方遭受的全部损失及（或）发生的全部费用，包括但不限于诉讼费、律师费、保全费等。
                    </p>
                    <p>3、贵司或贵司法定代表人根据《民法典》、《公司法》及其贵司内部章程约定保证具备签订本授权书的一切权利能力和行为能力，贵司法定代表人均具备足够的权限代表贵司签订本授权书及/或在贵司账号项下进行相关操作。如果因贵司账户被盗或被第三方非法利用，导致贵司的商业机密信息被泄漏、被窃取、被非法使用，进而导致贵司产生任何损失的，与本公司及合作方无关。
                    </p>
                    <p>4、贵司对自身提供的所有信息真实性、完整性、准确性承担全部责任。</p>
                    <p>&nbsp;</p>
                    <p>九、知识产权</p>
                    <p>贵司确认，本公司及合作方提供服务过程中涉及的所有内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归属提供方所有。除另有特别声明外，<strong>本公司或合作方在合作过程中依托本次经营数据所产生的</strong><strong>软件著作权</strong><strong>、</strong><strong>专利权</strong><strong>、非专利性技术</strong><strong>及其他知识产权</strong><strong>归属于本公司或合作方各自所有。</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>十、免责条款</p>
                    <p>贵司理解并知晓本协议所述内容对贵司的重要性。贵司理解并同意，本公司及合作方在服务过程中并不能完全避免如下风险的产生。<strong>在本公司及合作方无过错的情况下，本公司及合作方无义务为如下风险负责：</strong>
                    </p>
                    <p><strong>政策风险：</strong>因国家法律、法规、行政规章或政策发生重大调整、变化或其他不可预知的意外事件。</p>
                    <p><strong>商业机密泄露风险：</strong>由于贵司采集的数据，可能不同程度上涉及贵司的商业机密，在信息采集、加工分析、存储、传输、查询、使用的过程中，可能因人为因素、操作不当、不可抗力等情形导致贵司的商业机密被泄漏。
                    </p>
                    <p><strong>信贷风险：</strong>经本公司整理加工后形成的报告可能与贵司自行制作的报告存在较大差异，影响贵司的资信状况。</p>
                    <p><strong>计算机系统风险：</strong>互联网传输可能会受到干扰、中断、延迟、数据错误、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为、网络服务平台或相关设备的定期或者不定期检修、维护，非本公司、合作方能控制的通讯设施、硬件故障以及不可抗力等因素，引致的数据采集的不准确、不及时或者服务中断。
                    </p>
                    <p>&nbsp;</p>
                    <p>十一、争议解决</p>
                    <p>若本协议内容或其执行发生任何争议，各方应友好协商解决，协商不成时，任何一方均可向被告住所地有管辖权的人民法院提起诉讼。</p>
                    <p>&nbsp;</p>
                    <p>十二、其他条款</p>
                    <p>1、本协议内容与相关业务的合同条款不一致的，以本协议的内容为准，但相关合同条款明确约定是针对本协议内容所做的修改的除外。</p>
                    <p><strong>2、</strong><strong>贵司通过法定代表人点击“同意”的行为即代表贵司完全接受本协议，在点击之前请贵司及法定代表人再次确认已知悉并完全理解本协议的全部内容。</strong>
                    </p>
                    <p>（本协议项下授权承诺的行为完全是由贵司独立、自主、谨慎做出。贵司及其法定代表人已仔细阅读上述所有条款，并已特别注意字体加粗的内容，本公司已应贵司要求对相关条款予以明确说明，贵司对所有条款的含义及相应的法律后果已全部知晓并充分理解，自愿做出上述授权、承诺和说明。）
                    </p>
                </div>
            </el-scrollbar>
            <div slot="footer" class="dialog-footer custom-footer">
                <el-button type="primary" @click="dialogVisible = false" class="full-width-button">取消</el-button>
                <el-button type="primary" @click="handleConfirm" class="full-width-button">我已阅读并同意</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisiblePassword" width="50%" title="授权协议">
            <div slot="footer" class="dialog-footer custom-footer">
                <el-button type="primary" @click="dialogVisiblePassword = false"
                    class="full-width-button">取消</el-button>
                <el-button type="primary" @click="handleConfirmPassword" class="full-width-button">我已阅读并同意</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisibleResponse" width="50%" title="提示">
            <div>{{ responseMessage.message === 'OK' ? '授权成功' : responseMessage.message }}</div>
            <div slot="footer" class="dialog-footer custom-footer">
                <el-button type="primary" @click="dialogVisibleResponse = false"
                    class="full-width-button">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<style scoped>
.container {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 100vh;
    /* 使容器占满整个视口高度 */
    background-image: url('../images/1542506567523430400科技展板-@2x.png');
    background-size: cover;
    background-position: center;
}

.box-card {
    width: 1050px;
}

.custom-footer {
    display: flex;
    justify-content: space-between;
}

.full-width-button {
    flex: 1;
    margin: 0 5px;
}

.agreement-content p {
    text-align: left;
}

.custom-tabs {
    width: 540px;
    margin-left: 60px;
    margin-top: 25px;
    margin-bottom: 8px;
}

.custom-tabs>>>.el-tabs__item {
    flex: 1;
    width: 180px;
    /* 确保每个 tab 项目的宽度是均分的 */
    text-align: center;
    /* 让每个 tab 的内容居中 */
}

.custom-input .el-input__inner {
    font-size: 14px;
    /* 设置输入框的字体大小 */
    height: 30px;
    /* 调整输入框的高度 */
    line-height: 30px;
    /* 调整行高以居中对齐文本 */
}

.form-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    max-width: 1200px;
}

.container {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 100vh;
    /* 使容器占满整个视口高度 */
    background-image: url('../images/1542506567523430400科技展板-@2x.png');
    background-size: cover;
    background-position: center;
}

.el-form-item__label {
    text-align: left;
    padding-bottom: 4px;
    /* 调整标签和输入框之间的间距 */
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.box-card {
    width: 1050px;
}
</style>
